import React, { useEffect } from 'react'
import PageTop from '../../components/simple/PageTop'
import { useNavigate, useParams } from 'react-router-dom';
import { useLangContext } from '../../i18n/ProvideLang';
import useFetchInfo from '../../hooks/useFetchInfo.hook';
import ArticleServices from '../../services/general/articleServices';
import ArticleDetailContainer from '../../components/complex/ArticleDetailContainer';
import Preloader from '../../components/simple/Preloader';

const ArticleDetail = () => {
    const {slug} = useParams();
    const {lang, localizationsItems} = useLangContext();
    const navigate = useNavigate();
    const {lang: lang2} = useParams();

    const {info, loading} = useFetchInfo(ArticleServices.view, slug);

    useEffect(() => {
        if(lang){
            if(lang !== lang2) {
                navigate(`/${lang}/articles/${slug}`)
            }
        }
    }, [lang])

  return (
    <>{loading ?
        <Preloader/>
    :
    info &&
        <div>
            <PageTop item={info} parentLink={`/${lang}/articles`} parentName={'Статьи'}/>
            <ArticleDetailContainer item={info}/>
        </div>
    }</>
  )
}

export default ArticleDetail