import React, { useEffect } from 'react'
import EventsServices from '../../services/general/eventsServices';
import { useNavigate, useParams } from 'react-router-dom';
import { useLangContext } from '../../i18n/ProvideLang';
import PageTop from '../../components/simple/PageTop';
import useFetchInfo from '../../hooks/useFetchInfo.hook';
import EventDetailContainer from '../../components/complex/EventDetailContainer';
import Preloader from '../../components/simple/Preloader';

const EventsDetail = () => {
    const {slug} = useParams();
    const {lang, localizationsItems} = useLangContext();
    const navigate = useNavigate();
    const {lang: lang2} = useParams();

    const {info, loading} = useFetchInfo(EventsServices.view, slug);

    useEffect(() => {
        if(lang){
            if(lang !== lang2) {
                navigate(`/${lang}/events/${slug}`)
            }
        }
    }, [lang])


  return (
    <>{loading ?
        <Preloader/>
    :
    <div>
        <PageTop item={info} parentLink={`/${lang}/events`} parentName={'События'}/>
        <EventDetailContainer item={info}/>
    </div>
    }</>
  )
}

export default EventsDetail