import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useLangContext } from '../../i18n/ProvideLang';
import useFetchItems from '../../hooks/useFetchItems';
import AppServices from '../../services/general/appServices';
import PageTop from '../../components/simple/PageTop';
import ArticlesContainer from '../../components/complex/ArticlesContainer';
import Preloader from '../../components/simple/Preloader';

const ArticlesPage = () => {
    const {lang} = useLangContext();
    const navigate = useNavigate();
    const {lang: lang2} = useParams();

    useEffect(() => {
        if(lang){
            if(lang !== lang2) {
                navigate(`/${lang}/articles`)
            }
        }
    }, [lang])

    const {
        items,
        loading,
        setFilterData
    } = useFetchItems(AppServices.page, 'articles')

  return (
    <>{loading ?
        <Preloader/>
    :
    <div>
        <PageTop item={items}/>
        <ArticlesContainer/>
    </div>
    }</>
  )
}

export default ArticlesPage