import React from 'react'
import { useLangContext } from '../../i18n/ProvideLang'
import { NavLink } from 'react-router-dom';

const ArticleCard = ({card}) => {
    const {lang} = useLangContext();
  return (
    <div className="post-slide1">
        <div className="post-img">
            <img src={'https://solution-0005.panama.kz' + card.image} alt="blog1"/>                  
        </div>
        <div className="post-review">
            <div className="blog_details">
                <NavLink to={`/${lang}`}>Услуги ИТ-решений </NavLink>
                <span className="meta-date pl-3">{card.date}</span>
            </div><br/>
            <h3 className="post-title"><NavLink to={`/${lang}/articles/${card.slug}`}>{card.title}</NavLink></h3>
            <p className="post-description" dangerouslySetInnerHTML={{__html: card.description}}></p>                            
        </div>
    </div> 
  )
}

export default ArticleCard