import React from 'react'

const FeatureCards = ({elem}) => {
  return (
	<div className="feature_card pt-100 pb-100" style={{backgroundImage: `url(https://solution-0005.panama.kz/${elem.steps_image})`}}>
		<div className="container">
			<div className="row">
                {elem.elements?.map((card, index) =>
                    <div className="col-md-4 col-sm-6 wow flipInY" data-wow-delay="0ms" data-wow-duration="2500ms" key={index}>
                        <div className="single_feature_card">
                            <div className="single_feature_six_seven_icon">
                                <div className="icon">
                                    <img src={'https://solution-0005.panama.kz' + card.image} alt="" />
                                </div>
                            </div>
                            <div className="single_feature_card_content">
                                <h4>{card.title}</h4>
                                <p>{card.subtitle}</p>
                            </div>
                        </div>
                    </div>
                )}
			</div>
		</div>
	</div>
  )
}

export default FeatureCards