import React from 'react'
import useFetchItems from '../../hooks/useFetchItems'
import ArticleServices from '../../services/general/articleServices'

const ArticleCategories = () => {
    const {
        items:categories,
    } = useFetchItems(ArticleServices.categories)

  return (
    <div id="categories-3" className="widget widget_categories">
        <h2 className="widget-title">Категории</h2>
        <ul>
            {categories?.map((card, index) =>
                <li className="cat-item cat-item-8" key={index}>
                    <a href="#/"><i className="fa fa-arrows"></i> {card.title}  <span>(26)</span></a>
                </li>
            )}
        </ul>
    </div>
  )
}

export default ArticleCategories