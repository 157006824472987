import React from 'react'
import { NavLink } from 'react-router-dom'
import { useLangContext } from '../../i18n/ProvideLang'

const ServiceCard = ({card}) => {
    const {lang} = useLangContext();
  return (
    <div className="whychoose_flipbox mb-30">
        <div className="whychoose_flipbox_font">
            <div className="whychoose_flipbox_inner">
                <div className="whychoose_flipbox_icon">
                    <div className="icon">
                        <img src="https://solution-0005.panama.kz/images/1685081772.64704eac008f0.png" alt=""/>
                    </div>
                </div>
                <div className="service_box_title">
                    <h3>{card.title}</h3>
                </div>
                <div className="service_box_desc"
                dangerouslySetInnerHTML={{__html: card.description}}>
                </div>
            </div>
        </div>
        <div className="whychoose_flipbox_back"
            style={{backgroundImage: `url(https://solution-0005.panama.kz/${card.preview_image})`}}
            >
            <div className="whychoose_flipbox_inner">
                <div className="flipbox_title">
                    <h3>{card.title}</h3>
                </div>
                <div className="flipbox_desc"
                    dangerouslySetInnerHTML={{__html: card.description}}>
                </div>
                <div className="flipbox_button">
                    <NavLink to={`/${lang}/services/${card.slug}`}>Подробнее<i className="fa fa-angle-double-right"></i></NavLink>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ServiceCard