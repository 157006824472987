import React, { useEffect } from 'react'
import PageTop from '../../components/simple/PageTop'
import useFetchItems from '../../hooks/useFetchItems'
import AppServices from '../../services/general/appServices'
import EventsContainer from '../../components/complex/EventsContainer'
import { useLangContext } from '../../i18n/ProvideLang'
import { useNavigate, useParams } from 'react-router-dom'
import Preloader from '../../components/simple/Preloader'

const EventsPage = () => {
    const {lang} = useLangContext();
    const navigate = useNavigate();
    const {lang: lang2} = useParams();

    useEffect(() => {
        if(lang){
            if(lang !== lang2) {
                navigate(`/${lang}/events`)
            }
        }
    }, [lang])

    const {
        items,
        loading,
        setFilterData
    } = useFetchItems(AppServices.page, 'events')

  return (
    <>{loading ?
        <Preloader/>
    :
    <div>
        <PageTop item={items}/>
        <EventsContainer/>
    </div>
    }</>
  )
}

export default EventsPage