import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const SliderMain = ({elem}) => {
    const options = {
        autoplayHoverPause: true,
        autoplayTimeout: 10000,
        autoplay: true,
        loop: true,
        dots: false,
        nav: true,
        navText: ["<i class='fa fa-long-arrow-left''></i>", "<i class='fa fa-long-arrow-right''></i>"],
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 1
            },
            992: {
                items: 1
            },
            1000: {
                items: 1
            },
            1920: {
                items: 1
            }
        }
    }

  return (
	<OwlCarousel className="slider_list owl-carousel" {...options}>
        {elem.elements?.map((card, index) =>
            <div className="slider_area d-flex align-items-center slider1" key={index} style={{backgroundImage: `url(https://solution-0005.panama.kz/${card.front_image})`}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="single_slider">
                                <div className="slider_content">
                                    <div className="slider_text">
                                        <div className="slider_text_inner">
                                            <h5 dangerouslySetInnerHTML={{__html: card.description}}></h5>
                                            <h1>{card.title}</h1>
                                        </div>
                                        <div className="slider_button pt-5 d-flex">
                                            <div className="button">
                                                <a href="portfolio.html">{card.button_text} <i className="fa fa-long-arrow-right"></i></a>
                                            </div>
                                        </div>
                                        <div className="slider-video">
                                            <div className="video-icon">
                                                <a className="video-vemo-icon venobox vbox-item" data-vbtype="youtube"
                                                    data-autoplay="true" href="https://youtu.be/BS4TUd7FJSg"><i
                                                        className="fa fa-play"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )}
	</OwlCarousel>
  )
}

export default SliderMain