import React from 'react'
import useFetchItems from '../../hooks/useFetchItems'
import ArticleServices from '../../services/general/articleServices'
import { useLangContext } from '../../i18n/ProvideLang'

const RecentArticles = () => {
    const {localizationsItems} = useLangContext();

    const {
        items,
    } = useFetchItems(ArticleServices.index)
  return (
    <div id="em_recent_post_widget-6" className="widget widget_recent_data">
        <div className="single-widget-item">
            <h2 className="widget-title">{localizationsItems?.recent_news}</h2>		
            {items?.items?.slice(0,5).map((card, index) =>		
                <div className="recent-post-item" key={index}>
                    <div className="recent-post-image">
                        <a href="#">
                            <img width="100" height="80" src={'https://solution-0005.panama.kz' + card.image} alt="blog-list1"/>					
                        </a>
                    </div>
                    <div className="recent-post-text">                       
                        <h4><a href="#">
                            {card.title}
                            </a>
                        </h4>					
                        <span className="rcomment">{card.date}</span>
                    </div>
                </div>
            )}
        </div>
    </div>	
  )
}

export default RecentArticles