import React from 'react'
import RecentArticles from '../simple/RecentArticles'
import ArticleCategories from '../simple/ArticleCategories'

const ArticleSidebar = () => {
  return (
    <div className="blog-left-side widget">
        <div id="search-3" className="widget widget_search">
            <div className="search">
                <form action="#" method="get">
                    <input type="text" name="s" value="" placeholder="Введите ключевое слово" title="Search for:"/>
                    <button type="submit" className="icons">
                        <i className="fa fa-search"></i>
                    </button>
                </form>
            </div>
        </div>
        <ArticleCategories/>
        <RecentArticles/>	
    </div>
  )
}

export default ArticleSidebar