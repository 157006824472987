import React from 'react'
import useFetchItems from '../../hooks/useFetchItems'
import ArticleServices from '../../services/general/articleServices'

const OurBlog = ({elem}) => {
    const {
        items,
        loading,
        pageCount,
        params,
        handlePageClick,
        setFilterData
    } = useFetchItems(ArticleServices.index)

    
  return (
	<div className="blog_post pt-70 pb-80">
		<div className="container">
			<div className="row">
				<div className="col-lg-9">
					<div className="section_title text_left mb-60 mt-3">
						<div className="section_sub_title uppercase mb-3">
							<h6>{elem.subtitle}</h6>
						</div>
						<div className="section_main_title">
							<h1>{elem.title}</h1>
						</div>
						<div className="em_bar">
							<div className="em_bar_bg"></div>
						</div>

					</div>
				</div>
				<div className="col-lg-3">
					<div className="section_button mt-50">
						<div className="button two">
							<a href="blog-default.html">See All Blogs</a>
						</div>
					</div>
				</div>
			</div>
			<div className="row">
                {items?.items?.slice(0,3).map((card, index) =>
                    <div className="col-lg-4 col-md-6 col-sm-12 wow flipInY" data-wow-delay="0ms" data-wow-duration="2500ms" key={index}>
                        <div className="post-slide">
                            <div className="post-img">
                                <img src={'https://solution-0005.panama.kz' + card.image} alt="blog1"/>
                                <div className="post-info">
                                    <ul className="category">
                                        <li>in <a href="#">Graphics</a></li>
                                        <li>by <a href="#">admin</a></li>
                                    </ul>
                                    <span className="post-date">{card.date}</span>
                                </div>
                            </div>
                            <div className="post-review">
                                <div className="blog_details">
                                    <a href="#">IT Solution </a>
                                    <span className="meta-date pl-3">{card.date}</span>
                                </div><br/>
                                <h3 className="post-title"><a href="#">{card.title}</a></h3>
                                <p className="post-description" dangerouslySetInnerHTML={{__html: card.description}}></p>
                                <div className="button two">
                                    <a href="blog-details.html">Read More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
			</div>
		</div>
	</div>
  )
}

export default OurBlog