import React from 'react'

const Packages = ({elem}) => {
  return (
	<div className="pricing-table  pt-80 pb-80">
		<div className="container">
			<div className="row">
				<div className="col-lg-12 wow fadeInRight" data-wow-delay="0.4s">
					<div className="section_title text_center mb-50 mt-3">
						<div className="section_sub_title uppercase mb-3">
							<h6>{elem.subtitle}</h6>
						</div>
						<div className="section_main_title">
							<h1>{elem.title}</h1>
						</div>
						<div className="em_bar">
							<div className="em_bar_bg"></div>
						</div>
					</div>
				</div>
			</div>
			<div className="row">
                {elem.elements?.map((card, index) =>
                    <div className="col-lg-4 col-md-6 wow flipInY" data-wow-delay="0ms" data-wow-duration="2500ms" key={index}>
                        <div className="pricingTable">
                            <div className="pricingTable-header">
                                <div className="price-value">
                                    <span className="amount">{card.price}</span>
                                    <span className="currency">{card.type}</span>
                                    <span className="duration">{card.date}</span>
                                </div>
                                <h3 className="title">{card.subtitle}</h3>
                            </div>
                            <div dangerouslySetInnerHTML={{__html: card.description}}></div>
                            <div className="button two">
                                <a href="pricing.html">Sign Up</a>
                            </div>
                        </div>
                    </div>
                )}
			</div>
		</div>
	</div>
  )
}

export default Packages