import React, { useEffect } from 'react'
import PageTop from '../../components/simple/PageTop'
import { useNavigate, useParams } from 'react-router-dom';
import { useLangContext } from '../../i18n/ProvideLang';
import ServiceServices from '../../services/general/serviceServices';
import useFetchInfo from '../../hooks/useFetchInfo.hook'
import ServiceDetailContainer from '../../components/complex/ServiceDetailContainer';
import Preloader from '../../components/simple/Preloader';

const ServicesDetail = () => {
    const {slug} = useParams();
    const {lang, localizationsItems} = useLangContext();
    const navigate = useNavigate();
    const {lang: lang2} = useParams();

    const {info, loading} = useFetchInfo(ServiceServices.view, slug);

    useEffect(() => {
        if(lang){
            if(lang !== lang2) {
                navigate(`/${lang}/services/${slug}`)
            }
        }
    }, [lang])

  return (
    <>{loading ?
        <Preloader/>
    :
    <div>
        <PageTop item={info} parentLink={`/${lang}/services`} parentName={'Услуги'}/>
        <ServiceDetailContainer item={info}/>
    </div>
    }</>
  )
}

export default ServicesDetail