import React, { useState } from "react";
import { Collapse } from "react-bootstrap";

const CustomCollapse = ({card}) => {
    const [open, setOpen] = useState(false);
    console.log(card)
  return (
    <div className="faq__accordion-wrapper">
        <button
                    className="faq__accordian-heading"
                    type="button"
                    onClick={() => setOpen(!open)}
                    aria-controls="example-collapse-text"
                    aria-expanded={open}
        >
            {card.title}
        </button>
        <Collapse in={open}>
            <div className="faq__accordion-content" id="example-collapse-text"
            dangerouslySetInnerHTML={{__html: card.description}}>
                
            </div>
        </Collapse>
    </div>
  );
};

export default CustomCollapse;
