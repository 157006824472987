import React, { useEffect } from 'react'
import PageTop from '../../components/simple/PageTop'
import useFetchInfo from '../../hooks/useFetchInfo.hook';
import ProjectServices from '../../services/general/projectServices';
import { useNavigate, useParams } from 'react-router-dom';
import { useLangContext } from '../../i18n/ProvideLang';
import ProjectDetailContainer from '../../components/complex/ProjectDetailContainer';
import Preloader from '../../components/simple/Preloader';

const ProjectDetail = () => {
    const {slug} = useParams();
    const {lang, localizationsItems} = useLangContext();
    const navigate = useNavigate();
    const {lang: lang2} = useParams();

    const {info, loading} = useFetchInfo(ProjectServices.view, slug);

    useEffect(() => {
        if(lang){
            if(lang !== lang2) {
                navigate(`/${lang}/projects/${slug}`)
            }
        }
    }, [lang])

  return (
    <>{loading ?
        <Preloader/>
    :
    <div>
        <PageTop item={info} parentLink={`/${lang}/projects`} parentName={'Проекты'}/>
        <ProjectDetailContainer item={info}/>
    </div>
    }</>
  )
}

export default ProjectDetail