import React from 'react'
import { useState } from 'react'
import { NavLink } from 'react-router-dom';
import MenuItem from '../simple/MenuItem';

const MobMenu = ({items}) => {
    const [open, setOpen] = useState(false);

  return (
    <div className="mobile-menu-area d-sm-block d-md-block d-lg-none">
        <div className="mobile-menu mean-container">
            <div className="mean-bar">
                <div onClick={() => setOpen(!open)} className={open ? "meanmenu-reveal meanclose" : "meanmenu-reveal"}><span></span><span></span><span></span></div>
                <nav className={open ? "mean-nav mean-nav-visible" : "mean-nav"}>
				   <ul className="nav_scroll">
                        {items?.map((it, index) =>
                            <MenuItem key={index} it={it} setClose={setOpen}/>
                        )}
					</ul>
				</nav>
            </div>
        </div>
    </div>
  )
}

export default MobMenu