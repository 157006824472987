import React from 'react'

const Workers = ({elem}) => {
  return (
	<div className="team-member pt-100 pb-80"
        style={{backgroundImage: `url(https://solution-0005.panama.kz/${elem.steps_image})`}}
        >
		<div className="container">
			<div className="row">
				<div className="col-lg-9">
					<div className="section_title text_left mb-60 mt-3">
						<div className="section_sub_title uppercase mb-3">
							<h6>{elem.subtitle}</h6>
						</div>
						<div className="section_main_title">
							<h1>{elem.title}</h1>
						</div>
						<div className="em_bar">
							<div className="em_bar_bg"></div>
						</div>

					</div>
				</div>
				<div className="col-lg-3">
					<div className="section_button mt-50">
						<div className="button two">
							<a href="team.html">Join Our Team</a>
						</div>
					</div>
				</div>
			</div>
			<div className="row">
                {elem.elements?.map((card, index) =>
                    <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 wow flipInY" data-wow-delay="0ms"
                        data-wow-duration="2500ms" key={index}>
                        <div className="our-team">
                            <img src={'https://solution-0005.panama.kz' + card.image} alt="team1"/>
                            <div className="team-content">
                                <h3 className="team-title">{card.name}<small>{card.position}</small></h3>
                            </div>
                        </div>
                    </div>
                )}
			</div>
		</div>
	</div>
  )
}

export default Workers