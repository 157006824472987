import React from 'react'
import PageTop from '../../components/simple/PageTop'
import useFetchItems from '../../hooks/useFetchItems'
import AppServices from '../../services/general/appServices'
import ProjectsContainer from '../../components/complex/ProjectsContainer'
import Preloader from '../../components/simple/Preloader'

const ProjectsPage = () => {
    const {
        items,
        loading,
        setFilterData
    } = useFetchItems(AppServices.page, 'projects')

  return (
    <>{loading ?
        <Preloader/>
    :
    <div>
        <PageTop item={items}/>
        <ProjectsContainer />
    </div>
    }</>
  )
}

export default ProjectsPage