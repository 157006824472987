import React, { useEffect } from 'react'
import About from '../../components/complex/About'
import FeatureCards from '../../components/complex/FeatureCards'
import SliderMain from '../../components/complex/SliderMain'
import About2 from '../../components/complex/About2'
import Workers from '../../components/complex/Workers'
import Packages from '../../components/complex/Packages'
import Reviews from '../../components/complex/Reviews'
import Partners from '../../components/complex/Partners'
import Advantages from '../../components/complex/Advantages'
import OurServices from '../../components/complex/OurServices'
import OurBlog from '../../components/complex/OurBlog'
import ContactForm from '../../components/complex/ContactForm'
import { useNavigate, useParams } from 'react-router-dom'
import { useLangContext } from '../../i18n/ProvideLang'
import AppServices from '../../services/general/appServices'
import useFetchItems from '../../hooks/useFetchItems';
import WhyChooseUs from '../../components/complex/WhyChooseUs'
import FaqComponent from '../../components/complex/FaqComponent'
import Preloader from '../../components/simple/Preloader'

const MainPage = () => {
    const navigate = useNavigate();
    const {lang} = useLangContext();
    const {lang: lang2} = useParams();
  
    useEffect(() => {
        if(lang){
            if(lang !== lang2) {
                navigate(`/${lang}`)
            }
        }
    }, [lang])

    const {
        items,
        loading,
    } = useFetchItems(AppServices.page, 'main')

  return (
    <>
    {loading ?
        <Preloader/>
        :
        <div>
            {items?.elements?.map((elem, index) => 
                <div key={index}>
                    {elem.type === "opening-slider" ?
                        <SliderMain elem={elem}/>
                    :
                    elem.type === "about" ?
                        <About elem={elem}/>
                    :
                    elem.type === "about-2" ?
                        <About2 elem={elem}/>
                    :
                    elem.type === "advantages" ?
                        <Advantages elem={elem}/>
                    :
                    elem.type === "advantages-2" ?
                        <FeatureCards elem={elem}/>
                    :
                    elem.type === "services-2" ?
                        <OurServices elem={elem}/>
                    :
                    elem.type === "workers" ?
                        <Workers elem={elem}/>
                    :
                    elem.type === "packages" ?
                        <Packages elem={elem}/>
                    :
                    elem.type === "reviews" ?
                        <Reviews elem={elem}/>
                    :
                    elem.type === "articles" ?
                        <OurBlog elem={elem}/>
                    :
                    elem.type === "form" ?
                        <ContactForm elem={elem}/>
                    :
                    elem.type === "partners" ?
                        <Partners elem={elem}/>
                    :
                    elem.type === "steps" ?
                        <WhyChooseUs elem={elem}/>
                    :
                    elem.type === "faq" ?
                        <FaqComponent elem={elem}/>
                    :
                        <></>
                    }
                </div>
            )}
        </div>
    }
    </>
  )
}

export default MainPage