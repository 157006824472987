import React from 'react'
import ArticleSidebar from './ArticleSidebar'
import ShareBlock from '../simple/ShareBlock'

const ArticleDetailContainer = ({item}) => {
  return (
    <div className="blog_area blog-details-area pt-100 pb-100" id="blog">
        <div className="container">		
            <div className="row">	
                <div className="col-lg-8 col-md-7 col-sm-12 col-xs-12">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="blog_details">
                                <div className="blog_dtl_content">
                                    <h2>{item.title}</h2>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="blog-meta">
                                                <div className="blog-meta-left">
                                                    <span><i className="fa fa-calendar"></i>{item.date}</span>
                                                </div>	
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="blog_dtl_top_bs pt-2">
                                                <span>Услуги ИТ-решений</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div dangerouslySetInnerHTML={{__html: item.description}}></div>
                                    <ShareBlock/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className=" col-lg-4 col-md-5 col-sm-12 col-xs-12 sidebar-right content-widget pdsr">
                    <ArticleSidebar/>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ArticleDetailContainer