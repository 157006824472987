import React from 'react'

const Preloader = () => {
  return (
	<div className="pre-loader-wrap">
		<div className="pre-loader triangle">
		  <svg viewBox="0 0 86 80">
			<polygon points="43 8 79 72 7 72"></polygon>
		  </svg>
		  <span>Digicrew Загрузка...</span>
		</div>
	</div>
  )
}

export default Preloader