import React from 'react'
import useFetchItems from '../../hooks/useFetchItems'
import PartnersServices from '../../services/general/partnersServices'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const Partners = () => {
    const options = {
        loop: true,
        autoplay: true,
        autoplayTimeout: 10000,
        dots: true,
        nav: false,
        navText: ["<i class='fa fa-long-arrow-left''></i>", "<i class='fa fa-long-arrow-right''></i>"],
        responsive: {
            0: {
                items: 2
            },
			450: {
                items: 3
            },
            768: {
                items: 4
            },
            1200: {
                items: 5
            },
            1920: {
                items: 5
            }
        }
    }

    const {
        items,
        loading
    } = useFetchItems(PartnersServices.index)

  return (
    items?.items &&
	<div className="brand_area bg_color2 pt-35 pb-15">
		<div className="container">
			<div className="row">
				<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">	
					<div className="row">
						<OwlCarousel className="brand_list owl-carousel curosel-style" {...options}>
                            {items.items.map((card, index) =>
                                <div className="col-lg-12 wow flipInY" data-wow-delay="0ms" data-wow-duration="2500ms" key={index}>
                                    <div className="single_brand mt-3 mb-5">
                                        <div className="single_brand_thumb">
                                            <img src={'https://solution-0005.panama.kz' + card.image} alt="logo-brand1" />
                                        </div>
                                    </div>
                                </div>
                            )}
						</OwlCarousel>
					</div>
				</div>
			</div>
		</div>
	</div>
  )
}

export default Partners