import React from 'react'
import useFetchItems from '../../hooks/useFetchItems'
import ServiceServices from '../../services/general/serviceServices'
import ServiceCard from '../simple/ServiceCard'

const OurServices = ({elem}) => {
    const {
        items,
    } = useFetchItems(ServiceServices.indexQuery, elem.ids)

  return (
	<div className="our-service slider4 pt-100 pb-100" 
    style={{backgroundImage: `url(https://solution-0005.panama.kz/${elem.steps_image})`}}
    >
		<div className="container">
			<div className="row">
				<div className="col-lg-12 wow fadeInRight" data-wow-delay="0.4s">
					<div className="section_title text_center white mb-55">
						<div className="section_sub_title uppercase mb-3">
							<h6>{elem.subtitle}</h6>
						</div>
						<div className="section_main_title">
							<h1>{elem.title}</h1>
						</div>
						<div className="em_bar">
							<div className="em_bar_bg"></div>
						</div>
					</div>
				</div>
			</div>
			<div className="row">
                {items?.items?.map((card, index) =>
                    <div className="col-lg-4 col-md-6 col-sm-12 col-xs-6 wow fadeInUp" data-wow-delay="0.4s" key={index}>
                        <ServiceCard card={card}/>
                    </div>
                )}
			</div>
		</div>
	</div>
  )
}

export default OurServices